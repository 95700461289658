import React, { ReactNode } from 'react';

import { I18nText, ClassName, PositionStyleEnum } from '../../../types';
import { IconsEnum } from '../../../assets/icons/types';

import { useDropdownState } from '../hooks/useDropdownState';

import { Popover } from '../../Popover';
import { PureIconRefButtonHelper } from '../../buttons/PureIconRefButtonHelper';

import { PopoverPlacement } from '../../Popover/popoverConstants';

const dropdownMenuOffset = 12;

interface DropdownHelperProps {
  buttonClassName?: ClassName;
  children: ReactNode;
  className?: ClassName;
  popoverClassName?: ClassName;
  dropdownOffset?: number;
  dropdownPlacement?: PopoverPlacement;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  i18nText?: I18nText;
  disabled?: boolean;
  iconPosition?: 'left' | 'right';
  popoverPositionStyle?: PositionStyleEnum;
  closeOnOuterClick?: boolean;
}

function DropdownHelper({
  buttonClassName,
  children,
  className,
  popoverClassName,
  dropdownOffset = dropdownMenuOffset,
  dropdownPlacement = PopoverPlacement.TOP,
  icon,
  iconClassName,
  i18nText,
  disabled,
  iconPosition,
  popoverPositionStyle,
  closeOnOuterClick
}: DropdownHelperProps) {
  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = useDropdownState();

  return (
    <div className={className || 'relative inline-flex'}>
      <PureIconRefButtonHelper
        className={
          buttonClassName ||
          'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-400 dark:text-gray-500 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
        }
        icon={icon}
        iconClassName={iconClassName}
        onClick={togglePopover}
        ref={referencePopoverCallbackRef}
        i18nText={i18nText}
        disabled={disabled}
        iconPosition={iconPosition}
      />

      {isPopoverOpen ? (
        <Popover
          className={
            popoverClassName ||
            'min-w-48 z-30 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700'
          }
          closePopover={closePopover}
          distanceOffset={dropdownOffset}
          positionStyle={popoverPositionStyle}
          placement={dropdownPlacement}
          referenceElement={referencePopoverElement}
          closeOnOuterClick={closeOnOuterClick}
        >
          {children}
        </Popover>
      ) : null}
    </div>
  );
}

export default DropdownHelper;
