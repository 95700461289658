import {
  BillGqlError,
  BillUUID,
  CheckoutBillGqlQuery,
  CheckoutBillGqlStatus,
  CheckoutBillIndexCacheKey
} from '../../billsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface CheckoutBillOptions {
  query: CheckoutBillGqlQuery;
  cacheKeys?: CheckoutBillIndexCacheKey[];
}

export interface CheckoutBillResponse<CheckoutBillRecordType> {
  checkoutBill: {
    status: CheckoutBillGqlStatus;
    recordUuid: BillUUID;
    record: CheckoutBillRecordType;
    errors: CheckoutBillError;
  };
}

export interface CheckoutBillInput {
  uuid: BillUUID;
  source: string;
}

export interface CheckoutBillError {
  fullMessages: BillGqlError;
}

const action = 'checkoutBill';

function useCheckoutBill<CheckoutBillRecordType>({
  query,
  cacheKeys
}: CheckoutBillOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    CheckoutBillInput,
    CheckoutBillResponse<CheckoutBillRecordType>,
    CheckoutBillError,
    CheckoutBillRecordType
  >({ action, cacheKeys, query });

  return {
    checkoutBillData: updateQueryData,
    checkoutBillError: updateQueryError,
    checkoutBillLoading: updateQueryLoading,
    checkoutBillErrorMessage: updateQueryErrorMessage,
    checkoutBill: updateQuery,
    checkoutBillReset: updateQueryReset
  };
}

export default useCheckoutBill;
