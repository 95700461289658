import React, { useCallback } from 'react';
import StripeCheckout from 'react-stripe-checkout';

import {
  IsLoading,
  ID,
  ClassName,
  IsDisabled,
  I18nText
} from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { StripeCheckoutButtonBill } from './StripeCheckoutButton.types';

import {
  CHECKOUT_BILL_QUERY,
  CheckoutBillQueryResponse
} from '../../../queries/checkoutBill.query';

import { useCheckoutBill } from '../../../hooks/useCheckoutBill';

import { useTranslate } from '../../../../../common/hooks/useTranslate';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { words, billsKeys } from '../../../../../locales/keys';

import { BillCache } from '../../../BillCache';
import { STRIPE_API_KEY } from '../../../../../config';

interface TokenType {
  id: ID;
}

interface StripeCheckoutButtonProps {
  bill: StripeCheckoutButtonBill;
  i18nText?: I18nText;
  className?: ClassName;
  isLoading?: IsLoading;
  disabled?: IsDisabled;
}

function StripeCheckoutButton({
  bill,
  i18nText,
  className,
  isLoading,
  disabled
}: StripeCheckoutButtonProps) {
  const { checkoutBill, checkoutBillErrorMessage, checkoutBillLoading } =
    useCheckoutBill<CheckoutBillQueryResponse>({
      query: CHECKOUT_BILL_QUERY,
      cacheKeys: [
        BillCache.showCacheKey(bill.uuid),
        BillCache.showPaymentCacheKey(bill.uuid)
      ]
    });

  const handleStripePayment = useCallback<(token: TokenType) => void>(
    (token) => {
      checkoutBill({ uuid: bill.uuid, source: token?.id });
    },
    [bill, checkoutBill]
  );

  const { t } = useTranslate();

  useShowToastOnErrorChange({ error: checkoutBillErrorMessage });

  return (
    <StripeCheckout
      reconfigureOnUpdate
      name={`${t(words.pay)} $${bill.amount}`}
      description={`${t(billsKeys.singular)} #${bill.id}`}
      amount={bill.amount * 100}
      token={handleStripePayment}
      currency="USD"
      stripeKey={STRIPE_API_KEY as string}
    >
      <PureIconButtonHelper
        className={className}
        i18nText={i18nText}
        disabled={disabled || isLoading || checkoutBillLoading}
        iconClassName="h-6 w-6 p-0.5"
        icon={IconsEnum.CASH}
      />
    </StripeCheckout>
  );
}

export default StripeCheckoutButton;
