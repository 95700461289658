import { usePopoverState } from '../../../Popover';

function useDropdownState() {
  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = usePopoverState();

  // useEffect(() => {
  //   window.addEventListener('blur', closePopover);
  //
  //   return () => {
  //     window.removeEventListener('blur', closePopover);
  //   };
  // });

  return {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  };
}

export default useDropdownState;
