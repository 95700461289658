import { gql } from 'graphql-request';

import { BillID, BillUUID } from '../billsTypes';

export interface CheckoutBillQueryResponse {
  id: BillID;
  uuid: BillUUID;
}

export const CHECKOUT_BILL_QUERY = gql`
  mutation CheckoutBill($uuid: ID!, $source: String!) {
    checkoutBill(input: { uuid: $uuid, source: $source }) {
      recordUuid
      status
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
