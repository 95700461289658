import React, { memo } from 'react';

import { StaticImageHelper } from '../../../../helpers/StaticImageHelper';

import payoneerLogo1 from '../../../../assets/images/payoneer/combined_logo.png';
import payoneerLogo2 from '../../../../assets/images/payoneer/combined_logo@2x.png';
import payoneerLogo3 from '../../../../assets/images/payoneer/combined_logo@3x.png';

const payoneerLogoSrcVersions = {
  '3x': payoneerLogo3,
  '2x': payoneerLogo2,
  '1x': payoneerLogo1
};

function PayoneerCheckoutPaymentSystems() {
  return (
    <div className="flex items-center justify-between m-auto max-w-full px-2 space-x-4 w-72">
      <div className="text-sm">Visa / Mastercard</div>
      <div>
        <StaticImageHelper
          className="h-8"
          src={payoneerLogo1}
          srcSetData={payoneerLogoSrcVersions}
          alt="Visa / Mastercard"
        />
      </div>
    </div>
  );
}

export default memo(PayoneerCheckoutPaymentSystems);
